.listing-page .tab-content.active{
    display: block !important;
}
.listing-page .tab-content{
    display: none;
    min-height: 170vh;
}
.tabs__custom-v2 {
    position: relative;
}
.tabs__custom-v2 .nav-pills {
    padding: 8px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
}
.tabs__custom-v2 .nav-pills .nav-item {
    color: var(--primary-color);
    background:white;
    border:2px solid var(--primary-color);
    padding: 8px;
    width: 40px;
    height: 40px;
    border-radius: 5px;
    margin: 0 5px;
    display: inline;
    cursor: pointer;
}
.tabs__custom-v2 .nav-pills-1 {
   width: 200px;
}
.tabs__custom-v2 .nav-pills .select-wrapper {
    flex-basis: 70%;
    text-align: start;
}
.tabs__custom-v2 .nav-pills .select-wrapper.main-category {
    flex-basis:100%;
}
.tabs__custom-v2 .nav-pills .nav-item:hover{
    color: var(--primary-color);
    background-color:white;
}
.tabs__custom-v2 .nav-pills .nav-item.active {
    background-color: var(--primary-color);
    color:white;
}
.tabs__custom-v2 .nav-pills .list-inline-item {
    position: relative;
    line-height: 30px;
}
.tabs__custom-v2 .nav-pills .list-inline-item .title-text {
    font-size: 14px;
    font-weight: 600;
    color: var(--primary-color);
    margin-right: 5px;
}
.tabs__custom-v2 .nav-pills .list-inline-item a {
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    color: #000;
}
.tabs__custom-v2 .nav-pills .list-inline-item a:hover {
    text-decoration: none;
}
.tabs__custom-v2 .nav-pills .list-inline-item .dropdown-menu {
    padding: 0;
    margin: 10px 0 0;
    border: 0;
    box-shadow: 0 1px 5px rgba(70, 70, 70, 0.2);
}
.tabs__custom-v2 .nav-pills .list-inline-item .dropdown-menu .dropdown-item {
    padding: 8px 16px;
    border-bottom: 1px solid #eee;
}
.tabs__custom-v2 .nav-pills .list-inline-item .dropdown-menu .dropdown-item:last-child {
    border-bottom: 0;
}
.tabs__custom-v2 .nav-pills .list-inline-item .dropdown-menu .dropdown-item:hover {
    color: #fff;
    text-decoration: none;
    background-color: var(--primary-color);
}
/*Start featured-units-section*/
.featured-units-section{
    position: relative;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0px 14px 20px 0 #808080b0;
    margin-top: 50px;
    margin-bottom:70px;
}
.featured-units-section .title h3{
    font-weight: 500;
    letter-spacing:1.5px;
}

.responsive-sidebar{
    position: absolute;
    left: 0;
    top: 115px;
    background:#000000de;
    display: none;
    width: 100%;
    height: 100%;
    text-align: start;
    padding: 20px;
}
.responsive-sidebar.active{
    display: block;
}
.responsive-sidebar label{color:white;}
@media screen and (min-width: 320px) and (max-width: 575px) {
    .tabs__custom-v2 .nav {
    margin-top: 30px;
}
.tabs__custom-v2 .nav-pills .nav-item .nav-link {
    padding: 0.5rem 8px;
}
}
@media screen and (min-width: 576px) and (max-width: 768px) {
    .tabs__custom-v2 .nav-pills {
    margin-top: 30px;
}
}