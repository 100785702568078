
  .mySwiper{
    height:420px;
  }
  .home-services-swiper{
    height: 300px;
  }

  .swiper-button-prev.swiper-button-disabled,
  .swiper-button-next.swiper-button-disabled{
    pointer-events: unset !important;
  }
  .categories-swiper .swiper-button-prev,
  .categories-swiper .swiper-button-next,
  .home-services-swiper .swiper-button-prev,
  .home-services-swiper .swiper-button-next,
  .my-card-swiper .swiper-button-prev,
  .my-card-swiper .swiper-button-next,
  .similar-properties-swiper .swiper-button-prev,
  .similar-properties-swiper .swiper-button-next
  {
    font-weight: bold;
    background:#ddddddc9;
    border-radius: 50%;
    color: white;
    border:2px solid white;
    width: 35px;
    height: 35px;
  }
  .categories-swiper .swiper-button-prev,
  .categories-swiper .swiper-button-next,
  .home-services-swiper .swiper-button-prev,
  .home-services-swiper .swiper-button-next,
  .similar-properties-swiper .swiper-button-prev
  .similar-properties-swiper .swiper-button-next
  {padding: 25px;}

  .my-card-swiper .swiper-button-prev,
  .my-card-swiper .swiper-button-next{
    padding: 16px;
  }
  .my-card-swiper .swiper-button-prev::after,
  .my-card-swiper .swiper-button-next::after{
    font-size: 16px !important;
  }
  .swiper .swiper-button-prev::after,
  .swiper .swiper-button-next::after{
    font-size: 20px;
  }



  /*start image slider*/
  .image-slider,
  .image-slider img{height: 500px;}
  .image-slider .swiper-wrapper{height: 100%;}
  @media (max-width:767px) {
    .image-slider,
  .image-slider img{height: 350px;}
  }
  /*End image slider*/