
.topbar {
    background:var(--primary-dark-color);
    /* background: linear-gradient(105.2deg, var(--secondary-color) 11.2%, var(--primary-color) 117.9%); */
    background: linear-gradient(105.2deg, var(--secondary-color) 11.2%, var(--primary-color) 80.9%);
    padding: 0;
    min-height: 50px;
}
.lang-span{
    display: block;
    background: white;
    color: var(--primary-color);
    font-weight: 600;
    font-size: 14px;
    height: 25px;
    width: 30px;
    text-align: center;
    line-height: 1.8;
    border-radius: 5px;
    cursor: pointer;
    text-transform: uppercase;
}
.home-sidebar .lang-span{
    color:white;
    background-color: var(--primary-color);
}
.profile-btn-wrapper .img-box{
    width: 50px;
    height:50px;
    border-radius: 50%;
    overflow: hidden;
}
.topbar .topbar-left .topbar-text {
    color: #fff;
    padding: 10px 0;
    font-size: 13px;
    line-height: 30px;
}
.topbar .topbar-right {
    text-align: right;
    line-height: 30px;
}
.topbar .topbar-right .topbar-link {
    display: flex;
    align-items: center;
    padding: 10px;
    padding-left: 0;
    margin: 0;
}
.topbar .topbar-right .topbar-link li {
    list-style: none;
    float: left;
    color: #fff;
    padding: 0 10px;
    font-size: 12px;
}
.topbar .topbar-right .topbar-link li a {
    color: #fff;
    font-size: 16px;
    white-space: nowrap;
}
.topbar .topbar-right .topbar-sosmed {
    display: inline-table;
    padding: 6px;
    padding-left: 0;
    margin: 0;
}
.topbar .topbar-right .topbar-sosmed li {
    list-style: none;
    float: left;
    font-size: 16px;
    padding: 0 10px;
}

.topbar .topbar-right .topbar-sosmed li a {
    color: #fff;
    font-size: 16px;
    position:relative;
    top: 0;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
}
.topbar .topbar-right .topbar-sosmed li a:hover {
   top:-3px;
}

/*media query*/
@media screen and (min-width: 576px) and (max-width: 768px) {
    .topbar .topbar-right .topbar-sosmed {
    display: none;
}
.topbar .topbar-right {
    margin: 0;
}
.topbar .topbar-left,.topbar .topbar-right {
    text-align: center;
}
}
@media screen and (min-width: 320px) and (max-width: 575px) {
    .topbar .topbar-right .topbar-link {
    padding: 0;
}
.topbar .topbar-right,.topbar .topbar-left .topbar-text {
    text-align: center;
}
.topbar .topbar-right .topbar-sosmed li {
    font-size: 12px;
}
.topbar .topbar-left .topbar-text {
    padding: 0;
}
}