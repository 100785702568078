.contact-us-page .box{
    box-shadow: -2px 2px 8px 0 #8080807a;
    border-top: 3px solid var(--primary-color);
    border-radius:5px;
}
@media screen and (min-width: 320px) and (max-width: 575px) {
    .wrap__contact-form h5 {
    margin-bottom: 25px;
}
.wrap__contact-open-hours .list-unstyled li {
    padding: 6px;
}
.wrap__contact-form .form-group .btn-contact {
    margin-top: none;
}
}
@media (max-width:992px) {
    .contact-items{
        position: relative !important;
    }
}