.mySwiper3{
    height: 550px;
}

@media (min-width:576px) and (max-width:767px) {
    .mySwiper3{
        height: 490px;
    }
}
/* @media (max-width:575px) {
    .mySwiper3{
        height:200px;
    }
} */