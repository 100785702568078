
.user-dashboard-nav .dashboard-nav{
    background-color: var(--primary-color);
    padding:10px;
}
.user-dashboard-nav .dashboard-nav .btn-box button{
   width: 50px;height: 50px;border-radius:50%;
}
.user-dashboard-nav .dashboard-nav .menu-wrapper .left-side{
    flex-basis: 50%;
    padding: 0 10px;
}
.user-dashboard-nav .dashboard-nav .logo-content{
    background-color: white;
    margin: 0 10px;
    border-radius: 50%;
    width: 70px;
    height: 70px;
    overflow: hidden;
}

.user-dashboard-nav .dashboard-nav .right-side{
    flex-basis: 50%;
}
/********************************************************************/

@media (max-width:576px) {
    .user-dashboard-nav .dashboard-nav .left-side{
        flex-basis:85% !important;
    }
    .user-dashboard-nav .dashboard-nav .right-side{
        flex-basis:15% !important;
    }
    .user-dashboard-nav .dashboard-nav .logo-content{
        width:60px;
        height: 60px;
    }
}