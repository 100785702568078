@import url(./css/normalize.min.css);
@import url(./css/style.css);
@import url(./css/custom.css);
*{margin: 0;padding: 0;color: unset;box-sizing: border-box;text-decoration: none;outline: none;}
:root{
/* --primary-color:#fc6f2e; */
/* --primary-color:#b17a34; */
/* --primary-color:#002247; */
/* --primary-color:#093f79; */
/* --secondary-color:#2e3134; */
/* --secondary-2-color:#2a2c2f; */
--primary-color:#ff6204;
--secondary-color:#093f79;
--secondary-2-color:#002247;
--white-color:#fff;
--light-primary:#fc6f2e69;
--facebook-color:#3b5999;
--twitter-color:#55acee;
--pinterest-color:#bd081c;
--skype-color:#00aff0;
--linkedin-color:#0077b5;
--instagram-color:#d91cac;
--google-plus-color:#dd4b39;
--youtube-color:#cc181e;
--telegram-color:#179cde;
--whatsapp-color:#25d366;
}
body { font-family: "Cairo", sans-serif !important;margin: 0;padding: 0;overflow-x: hidden;font-size: 16px;}
ul {list-style: none;padding: 0 !important;}
a {text-decoration: none !important;outline: none;color: inherit;}
section{position: relative;}
input[type="password"]{user-select: none;}
p,h1,h2,h3,h4,h5,span,a,ul,li,input
{font-family: "Cairo", sans-serif !important;}
::-webkit-scrollbar {
    width: 10px;
  }
    ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
    ::-webkit-scrollbar-thumb {
    background: var(--primary-color); 
      border-radius:10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    
    background:var(--primary-color); 
  } 