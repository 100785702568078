.btn {
  width: 100%;
  display: inline-block;
  font-weight: 600;
  text-transform: capitalize;
  font-size: 16px;
  padding: 10px 15px;
  text-wrap: nowrap !important;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 1.5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-color: var(--primary-color) !important;
  border: 2px solid transparent !important;
  color: white !important;
  border-radius: 5px ;
}
@media (max-width:575px) {
    .btn{
      font-size: 14px;
    }
}
.btn span {
  color: inherit !important;
}
.btn:hover {
  background-color: white !important;
  color: var(--primary-color) !important;
  border: 2px solid var(--primary-color) !important;
  border-radius: 5px;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn.disabled,
.btn:disabled {
  opacity: 0.65;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}
.facebook{
  background-color: #3b5999 !important;
}
