.section__breadcrumb{
    background-image: url(../../../images/bg.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 0;
}
.section__breadcrumb,
.section__breadcrumb .child{
    min-height: 50vh;
}
.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: .75rem 1rem;
    margin-bottom: 1rem;
    list-style: none;
    background-color: #e9ecef;
}
.breadcrumb-item+.breadcrumb-item {
    padding-left: .5rem;
}
.breadcrumb-item+.breadcrumb-item::before {
    display: inline-block;
    padding-right: .5rem;
    color: #6c757d;
    content: "/"}
.breadcrumb-item+.breadcrumb-item:hover::before {
    text-decoration: underline;
}
.breadcrumb-item+.breadcrumb-item:hover::before {
    text-decoration: none;
}
.breadcrumb-item.active {
    color: #6c757d;
}