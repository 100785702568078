
.cta,.categories-analysis {
  height:200px;
  background-image: url(../../../images/frame.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.categories-analysis{height: 250px;}

.cta h3,.categories-analysis h3{
  font-size:40px;
}
.cta p{
  font-size:30px;
}

.cta-overlay {
  position: absolute;
  background-color:#002247de;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  bottom: 0;
}

@media (max-width:1200px) {
  .cta h3{
    font-size: 30px;
    line-height: 5;
  }
  .categories-analysis{height: auto;}
}
@media (max-width:992px) {
  .cta{
    height: auto;
  }
}
