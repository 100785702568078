.auth-card .user-type{
    display: flex;
    align-items:center;
}
.user-type-tab-content .form-row{
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    display: none;
}
.user-type-tab-content .active{
    display: block;
}
@media (max-width:576px) {
    .auth-card .user-type{
        flex-direction: column;
        align-items:start;
    }
    .auth-card .form-group.user-type-item{
        margin: 5px 0;
    }
}