.filter-sidebar .filter-group .items-wrapper{
    scrollbar-width: none;
    overflow-y: auto;
    height:80vh;
}
.filter-sidebar.fixed-sidebar-ar{
    right: 0;
}
.filter-sidebar.fixed-sidebar-ar .sidebar-content{border-top-left-radius: 20px;}
.filter-sidebar.fixed-sidebar-en{
    left: 0;
}
.filter-sidebar.fixed-sidebar-en .sidebar-content{border-top-right-radius: 20px;}


.filter-sidebar .filter-items .filter-item{
    flex-basis: calc(90% / 3);
    text-align: center;
    margin: 0 5px;
}
.filter-sidebar .filter-2-item .filter-item{
    flex-basis: calc(90% / 2);
    text-align: center;
    margin: 0 5px;
    text-wrap: nowrap;
}

.filter-sidebar .filter-group .items-wrapper li{font-size: 16px; text-wrap: nowrap;}



.filter-sidebar .filter-group label{
    font-size: 16px;
    color:var(--secondary-2-color);
    font-weight: bold;
}

.filter-sidebar .input-options{
    max-height: 300px;
    overflow-y: auto;
}


/*media query*/

@media (max-width:767px) {
    .filter-sidebar .filter-items .items-wrapper li,
    .filter-sidebar .filter-group .items-wrapper li{font-size: 14px;}
    
    .filter-sidebar .category-filter-items li{
        width: 80px;
        height: 80px;
      }
}