.main-category-list{
    margin: 0;
}
.main-category-list li{
    padding:10px;
    background-color:white;
    color:var(--primary-color);
    border-radius:5px;
    margin: 0 5px;
    cursor: pointer;
    font-weight: 600;
    border:2px solid var(--primary-color);
    width: 120px;
}
.main-category-list li:hover{
    background-color:var(--primary-color);
    color:white;
}
.main-category-list li.active{
    background-color:var(--primary-color);
    color:white; 
}
.show-filter:active{
    top: 4px;
}
@media (max-width:576px) {
    .main-category-list li{font-size: 14px;width:fit-content;}   
}