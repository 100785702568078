.profile__agent {
    border: 1px solid #e9ecef;
}
@media screen and (min-width: 320px) and (max-width: 575px) {
    .profile__agent {
    margin-top: 20px;
}
}.profile__agent .profile__agent__group {
    border-bottom: 1px solid #e9ecef;
}
.profile__agent .profile__agent__group .profile__agent__header {
    padding: 1rem;
    margin-bottom: 0;
    display: block;
}
.profile__agent .profile__agent__group .profile__agent__header img {
    position: relative;
    border-radius: 50%}
.profile__agent .profile__agent__group .profile__agent__header h5 {
    text-transform: capitalize;
    color: #212529;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0;
    line-height: 24px;
    font-family: "Open Sans", serif;
    margin-bottom: 10px;
}
.profile__agent .profile__agent__group .profile__agent__header-avatar {
    display: flex;
}
.profile__agent .profile__agent__group .profile__agent__header-avatar figure {
    float: left;
    margin-bottom: 0;
}
.profile__agent .profile__agent__group .profile__agent__header-avatar figure img {
    width: 90px;
    height: 90px;
    position: relative;
}
.profile__agent .profile__agent__group:last-child {
    border-bottom: 0;
}
.profile__agent .profile__agent__group .profile__agent__body {
    padding: 1rem;
    border-top: 1px solid #e9ecef;
    transition: all linear 0.25s;
}
.profile__agent .profile__agent__group .profile__agent__footer {
    padding: 1rem;
    border-top: 1px solid #e9ecef;
    transition: all linear 0.25s;
}
.profile__agents {
    position: relative;
    box-sizing: border-box;
    transition: all 0.4s;
}
.profile__agents-avatar {
    position: relative;
    display: block;
}
.profile__agents-avatar img {
    height: 311px;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}
.profile__agents-info {
    display: block;
}
@media screen and (min-width: 320px) and (max-width: 575px) {
    .profile__agents-info {
    padding: 20px;
}
}.profile__agents-info h5 {
    font-family: "Open Sans", serif;
    font-size: 18px;
}
.profile__agents-info h5 a {
    color: #000;
}
.profile__agents-info h5 a:hover {
    text-decoration: none;
    color: var(--primary-color);
}
.profile__agents-info ul {
    padding: 0;
    margin: 0;
}
.profile__agents-info ul li {
    margin-bottom: 10px;
}
.profile__agents-info ul li a {
    color: #002247;
    font-size: 14px;
}
.profile__agents-info ul li a span {
    width: 100px;
    display: inline-block;
}
.profile__agents-info ul li a span i.fa {
    text-align: center;
    width: 30px;
    height: 30px;
    line-height: 30px;
    margin-right: 5px;
    color: var(--primary-color);
    background-color: #c1d0ff;
}
.profile__agents-info ul li a:hover {
    text-decoration: none;
    color: var(--primary-color);
}
.profile__agents .cards {
    margin-top: 30px;
}
.agent-card{
    margin: 5px;
    border: 1px solid transparent;
}
.profile__agents .agent-card:hover {
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}
.profile__agents .cards .profile__agents-body {
    padding: 20px;
}
@media screen and (min-width: 320px) and (max-width: 575px) {
    .profile__agents .cards .profile__agents-body {
    padding: 0;
}
}.total__property-agent {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 3px 6px;
    background-color: #002247;
    box-shadow: 1px 2px 1px rgba(94, 93, 99, 0.08), 0 1px 2px rgba(61, 60, 66, 0.12);
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 14px;
    color: #fff;
    font-family: "Open Sans", sans-serif;
}