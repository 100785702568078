.news-card{
    height: 183px;
}
.news-card .card-content{
    display: flex;
    align-items:center;
}
.news-card .card-content img{
    border-top-left-radius: 30px;
    border-bottom-right-radius: 30px;
    max-width: 40%;
}
.news-card .card-content .card-body{
  max-width: 60%;
}
.news-card .card-content .description{
    min-height: 80px;
}