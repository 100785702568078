.user-profile-page{
    display: flex;
    justify-content:flex-end;
}
.dashboard-main-content{
    background-color: var(--section-bg);
    min-height: 100%;
    position: relative;
}
.user-profile-page .dashboard-main-content .row-content h3{
    font-size: 18px;
}
.dashboard-main-content .row-footer{
    padding: 10px;
    background: var(--primary-color);
    color: white;
}
.company-name{
    color: var(--white-color);
    font-weight: bold;
    margin: 0 5px;
}
/*local css in these sections*/
.dashboard-main-content .row-content{
    width: 90%;
    background: white;
    text-align: start;
    border-radius: 10px;
    position: relative;
    box-shadow: 0 0 5px 0px #ccc;
    margin: 0 auto;
    top:-50px;
    padding: 15px;
    transition: all 0.3s;
    animation: fadeeUp 0.3s linear forwards;
}
@keyframe fadeeUp {
    0%{
        top:0px;
    }
    50%{
        top: -35px;
    }
    100%{
        top: -50px;
    }
}
/*Start user sidebar wrap*/

/*End user sidebar wrap*/
@media (max-width:767px) {
    .dashboard-main-content .row-content{
        margin: 30px auto !important;
    }
}
@media (max-width:992px) {
    .user-profile-page .user-dashboard{
        flex-basis:100%;
    }
}