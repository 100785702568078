.service-card{
    box-shadow: 1px 2px 11px 0px #8080808c;
    cursor: pointer;
}
.service-img,
.service-card-swiper{
    min-height: 300px;
    max-height: 300px;
    height: 300px;
}
.service-img .img-content{
    height: 300px;
}