/*start fixed sidebar style*/
.fixed-sidebar{
    overflow: hidden;
    position: fixed;
    top: 0;
    width: 0px;
    height: 100vh;
    z-index: 2000 !important;
    background:#0000009c;
    text-align: start;
}
.fixed-sidebar.fixed-sidebar-ar{
    right: 0;
    
}
.fixed-sidebar.fixed-sidebar-ar .sidebar-content{border-top-left-radius: 20px;}
.fixed-sidebar.fixed-sidebar-en{
    left: 0;
}
.fixed-sidebar.fixed-sidebar-en .sidebar-content{border-top-right-radius: 20px;}

.fixed-sidebar .sidebar-content{
    width: 250px;
    background-color: var(--white-color);
    height: 100%;
}
.filter-sidebar .sidebar-content{
    width: 700px;
    

}
.fixed-sidebar.active{width:100% !important;}
.fixed-sidebar .author-img img{max-width:100%;width: 100%; height: 100%;}
.fixed-sidebar .author-bio .author__title{color: black;}
.fixed-sidebar a{color: black;}
.fixed-sidebar .author-content{border-bottom: 2px solid var(--primary-color); padding:15px 10px;}
.fixed-sidebar .sidebar-menu a,
.fixed-sidebar .sidebar-menu li
{font-size:18px; font-weight: 600;}
.fixed-sidebar .setting-content{
    font-size:14px;
    font-weight: bold;
    position:absolute;
    bottom:0;
    cursor: pointer;
    background:white;
}
.author-img{
    width: 50px;
      height: 50px;
      border-radius: 50%;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 5px;
      cursor: pointer;
}
/*End fixed sidebar style*/

/*media query*/
@media (max-width:992px) {
    .filter-sidebar.active .sidebar-content{
        width: auto;
    }
}