.project-card .project-title{
    font-size: 18px;
    cursor: pointer;
}
.profile__agency-info .project-title:hover {
    color: var(--primary-color);
}
.profile__agency .project-card {
    position: relative;
    border: 1px solid #eee;
    box-sizing: border-box;
    transition: all 0.4s;
    height: 100%;
}
.profile__agency .project-card:hover,
.profile__agency .cards:hover {
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}
.profile__agency-logo {
    position: relative;
    border-right: 1px solid #eee;
    min-height: 250px;
    height: 250px;
    cursor: pointer;
}
.profile__agency-logo img {
    -o-object-fit: cover;
    object-fit: cover;
    height: 100%;
    width: 100%;
}
.profile__agency-info {
    display: block;
    padding: 15px;
    text-align: start;
}
.profile__agency-info ul {
    padding: 0;
    margin: 0;
}
.profile__agency-info ul li {
    margin-bottom: 10px;
}
.profile__agency-info ul li {
    font-size: 15px;
    color: #000;
    font-weight: 500;
}
.profile__agency-info ul li span {
    display: inline-block;
    margin: 0 2px;
}
.profile__agency-info ul li span i.fa {
    text-align: center;
    width: 30px;
    height: 30px;
    line-height: 30px;
    margin-right: 5px;
    color: var(--primary-color);
    background-color: #c1d0ff;
}
.profile__agency-info ul li:hover {
    text-decoration: none;
    color: var(--primary-color);
}
.profile__agency .cards {
    position: relative;
    border: 1px solid #eee;
    box-sizing: border-box;
    transition: all 0.4s;
    margin-top: 30px;
}
.profile__agency .cards .profile__agency-header {
    border-bottom: 1px solid #eee;
}
.profile__agency .cards .profile__agency-header .profile__agency-logo {
    border: 0;
    min-height: 240px;
}
.profile__agency .cards .profile__agency-body {
    padding: 20px;
}

.total__property-agency {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 3px 6px;
    background-color: #002247;
    box-shadow: 1px 2px 1px rgba(94, 93, 99, 0.08), 0 1px 2px rgba(61, 60, 66, 0.12);
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 14px;
    color: #fff;
}
@media (max-width:576px) {
    .project-card span{font-size: 14px;}
}
@media screen and (min-width: 320px) and (max-width: 575px) {
    .profile__agency .cards .profile__agency-body {
    padding: 0;
}
.profile__agency-logo {
    border-right: 0;
    border-bottom: 1px solid #eee;
}
.profile__agency-info {
    padding:5px;
}
}
