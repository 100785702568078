.card__image:hover {
    cursor: pointer;
    -o-box-shadow: 0px 0px 30px 0px rgba(19, 19, 28, 0.12);
    box-shadow: 0px 0px 30px 0px rgba(19, 19, 28, 0.12);
}
.card__image .img-space {
    margin: 10px 10px 0;
}
.card__image__header {
    position: relative;
    overflow: hidden;
    display: block;
    height: 100%;
    max-height: 100%;
}
.card__image__header::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40%;
    background: linear-gradient(transparent,  #1b1b1b);
    z-index: 1;
}
.card__image__header .ribbon {
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    transform: translate3d(0,  0,  1px) rotate(-45deg);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background-color: var(--primary-color);
    color: #fff;
    font-size: 14px;
    line-height: 20px;
    font-family: "Open Sans", sans-serif;
    padding: 6px;
    position: absolute;
    z-index: 3;
    left: -30px;
    top: 15px;
    width: 120px;
    text-align: center;
    margin: auto;
    height: 30px;
    bottom: inherit;
    right: inherit;
}
.card__image__header .info {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 2px 5px;
    background-color: #002247;
    box-shadow: 1px 2px 1px rgba(94, 93, 99, 0.08), 0 1px 2px rgba(61, 60, 66, 0.12);
    text-transform: capitalize;
    letter-spacing: 1px;
    font-size: 14px;
    color: #fff;
    font-family: "Open Sans", sans-serif;
}
.card__image__header img.img-transition {
    height: 250px;
    max-width: 100%;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    transition: all 0.3s linear 0s;
}
.card__image__header:hover img.img-transition {
    transform: scale(1.05);
}
.card__image__header:hover::before {
    opacity: 1;
}
.card__image__body {
    padding: 1.5rem 1rem;
    position: relative;
}
@media screen and (min-width: 576px) and (max-width: 768px) {
    .card__image__body {
    padding: 1rem 1rem;
}
}.card__image__body span {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
}
.card__image__body h6 {
    font-family: "Open Sans", serif;
}
.card__image__body h6 a {
    color: #002247;
    text-transform: capitalize;
    font-family: "Open Sans", serif;
}
.card__image__body h6 a:hover {
    text-decoration: none;
}
@media screen and (min-width: 576px) and (max-width: 768px) {
    .card__image__body h6 {
    font-size: 16px;
}
}.card__image__body .list-inline {
    line-height: 24px;
    margin-bottom: 0;
}
.card__image__body .list-inline-item span {
    text-align: center;
    font-family: "Open Sans", sans-serif;
    text-transform: capitalize;
    font-weight: 500;
    font-size: 14px;
    color: #495057;
}
.card__image__body .list-inline-item span i.fa {
    color: #495057;
}
.card__image__body .card__content {
    width: 100%;
    color: #fff;
    transition: all 0.3s;
    margin: 0;
    list-style: none;
    font-size: 14px;
    font-weight: 600;
}
@media screen and (min-width: 576px) and (max-width: 768px) {
    .card__image__body .card__content {
    display: none;
}
}.card__image__footer-first {
    align-items: center;
}
.card__image__footer-first::before {
    border: 1px solid gray;
}
.card__image__footer {
    height: 100%;
    border-left: 1px solid #e9ecef;
    padding: 1.5rem 1rem;
    margin-bottom: auto;
    margin-top: auto;
    align-items: center;
    text-align: center;
}
@media screen and (min-width: 320px) and (max-width: 575px) {
    .card__image__footer {
    display: flex;
    padding: 0.5rem 1rem;
    border-left: none;
    border-top: 1px solid #e9ecef;
    text-align: left;
}
}.card__image__footer figure {
    position: relative;
    display: flex;
    width: 50px;
    height: 50px;
    margin: 0 auto;
}
@media screen and (min-width: 320px) and (max-width: 575px) {
    .card__image__footer figure {
    margin: 0 10px 0 0;
}
}.card__image__footer figure img {
    max-width: 100%;
    height: auto;
    border: 1px solid #e9ecef;
    padding: 3px;
    vertical-align: middle;
}
.card__image__footer .name {
    padding-top: 20px;
}
@media screen and (min-width: 320px) and (max-width: 575px) {
    .card__image__footer .name {
    padding-top: 0px;
}
}.card__image__footer .price {
    padding-top: 20px;
}
@media screen and (min-width: 320px) and (max-width: 575px) {
    .card__image__footer .price {
    padding-top: 5px;
}
}.card__image__footer .list-inline-item a {
    text-transform: capitalize;
    font-family: "Open Sans", sans-serif;
    color: #002247;
}
.card__image__footer .list-inline-item a:hover {
    text-decoration: none;
}
.card__image__footer .list-inline-item span, .card__image__footer .list-inline-item small {
    font-family: "Open Sans", sans-serif;
    font-size: 11px;
    text-transform: capitalize;
    font-weight: 600;
}
.cards__icons {
    margin-top: 30px;
    padding: 40px 30px;
    position: relative;
    transition: all 0.3s ease;
}
.cards__icons:hover {
    cursor: pointer;
    -o-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
    box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
    transform: translateY(-15px);
}
.cards__icons:hover .cards__icons-header-hover {
    background-color: var(--primary-color);
}
.cards__icons-left {
    border: 1px solid gray;
}
.cards__icons-header {
    position: relative;
}
.cards__icons-header-hover {
    background-color: #a7d1ff;
    height: 130px;
    line-height: 130px;
    margin: 0 auto;
    width: 130px;
    transition: all 0.3s ease;
}
.cards__icons-header .circle {
    border-radius: 50%}
.cards__icons-footer {
    padding-top: 30px;
}
.cards__icons-footer h5 {
    font-family: "Open Sans", serif;
}
.cards__icons-footer p {
    line-height: 1.5;
    margin-bottom: 0;
}
.cards__icons-style-1 {
    margin-bottom: 30px;
    position: relative;
    transition: all 0.3s ease;
}
.cards__icons-style-1:hover {
    cursor: pointer;
    transform: translateY(-15px);
}
.cards__icons-style-1:hover .cards__icons-header-hover {
    background-color: var(--primary-color);
}
.cards__icons-style-1 .cards__icons-header-left {
    text-align: left;
}
.cards__icons-style-1-footer {
    padding-top: 30px;
}
.cards__icons-style-1-footer h5 {
    font-family: "Open Sans", serif;
}
.cards__icons-style-1-footer p {
    line-height: 1.5;
    margin-bottom: 0;
}