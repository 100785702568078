.form-group{
    margin: 20px 0;
}
.wd-fit{
    width: fit-content !important;
}
/*Start input*/
.custom-input{
    font-size: 16px;
    padding: 10px;
    border-radius: 10px;
    outline: none;
    width: 100%;
    border: 1px solid #ddd;
    transition: all 0.3s;
    direction: inherit;
    background-color:#f5f5f5;
}
.custom-input:focus{
    box-shadow: 0px 2px 5px 0px var(--primary-color);
}
.color-primary{color:var(--primary-color)}
.color-sec{color:var(--secondary-color)}
.color-sec2{color:var(--secondary-2-color)}
.bg-pri{background-color:var(--primary-color)}
.bg-light-pri{background-color:#ff62043d}
.bg-sec{background-color:var(--secondary-color) !important}
.bg-sec-2{background-color:var(--secondary-2-color) !important}
.bg-light-gray{background-color:#eee}
/*End input*/
/*Start font*/
.fs-10{font-size: 10px !important;}
.fs-12{font-size: 12px !important;}
.fs-14{font-size: 14px !important;}
.fs-16{font-size: 16px !important;}
.fs-18{font-size: 18px !important;}
.fs-20{font-size: 20px !important;}
.fs-22{font-size: 22px !important;}
.fs-36{font-size: 36px !important;}
.fs-55{font-size: 55px !important;}
.w-22px{width: 22px;}
.h-22px{height: 22px;}
.w-35px{width: 35px;}
.h-35px{height: 35px;}
.w-50px{width: 50px;}
.h-50px{height: 50px;}
.w-100px{width: 100px;}
.h-100px{height: 100px;}
.w-150px{width: 150px;}
.h-150px{height: 150px;}
.w-200px{width: 200px;}
.h-200px{height: 200px;}
.w-250px{width: 250px;}
.h-250px{height: 250px;}
.w-350px{width: 350px;}
.h-350px{height: 350px;}
.w-500px{width: 500px;}
.h-500px{height: 500px;}

.w-95{width: 95%;}
/*End font*/
.txt-start{text-align: start;}
.min-h-100{min-height:100vh;}
.radius-5{border-radius:5px;}
.radius-10{border-radius:10px !important;}
.radius-50{border-radius:50% !important;}
.hover-1{
  color:white !important;
  background-color:var(--primary-color) !important;
}
.hover-1:hover{
  color:var(--primary-color) !important;
  background-color:white !important;
}
.hover-2{
  color:var(--primary-color) !important;
  background-color:white !important;
}
.hover-2:hover{
  color:white !important;
  background-color:var(--primary-color) !important;
}

/*ellipses*/
.ellipses{
  max-width:280px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

/*Start comment style*/
.pointer{cursor: pointer;}
.comment-wrapper{
    display: flex;
    margin: 20px 0;
}
.img-wrapper{
    width: 60px;
    height: 60px;
    flex-shrink: 0;
}
.img-wrapper img{
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
}
/*End comment style*/
/*Start list row*/
.list-row{
    display: flex;
    align-items: center;
    margin: 10px 0;
}
.list-row-item{
    margin: 0 3px;
}
/*End list row*/
/*Start btn*/
.btn-social {
    font-size: 16px;
    width: 32px;
    height: 32px;
    border-radius:50%;
    text-align: center;
    margin: 0 auto;
    line-height: 32px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s all;
    color:var(--white-color) !important;
    border:2px solid transparent;
  }
  .btn-social:hover{
    background-color:var(--white-color) !important;
}
  .btn-social i {
    margin-right: 0px;
  }
  .btn-social.facebook {
    background-color: var(--facebook-color) ;
  }
  .btn-social.share {
    background-color: var(--white-color) ;
    border-color: var(--twitter-color);
    color: var(--twitter-color) !important;
  }
  .btn-social.twitter,.bg-twitter {
    background-color:var(--twitter-color);
  }
  .btn-social.whatsapp,.bg-whatsapp {
    background-color: var(--whatsapp-color);
  }
  .btn-social.telegram,.bg-telegram {
    background-color: var(--telegram-color);
  }
  .btn-social.pinterest,.bg-pinterest {
    background-color:var(--pinterest-color);
  }
  .btn-social.skype.bg-skype {
    background-color:var(--skype-color);
  }
  .btn-social.linkedin,.bg-linkedin {
    background-color:var(--linkedin-color) ;
  }
  .btn-social.instagram,.bg-instagram {
    background-color: var(--instagram-color);
  }
  .btn-social.google-plus,.bg-google-plus {
    background-color:var(--google-plus-color);
  }
  .btn-social.youtube,.bg-youtube {
    background-color:var(--youtube-color);
  }
  .btn-social.tiktok,.bg-tiktok {
    background-color:black;
  }
  .btn-social:hover.facebook {
    color: var(--facebook-color) !important;
    border:2px solid var(--facebook-color) !important;
  }
  .btn-social:hover.whatsapp {
    color: var(--whatsapp-color) !important;
    border:2px solid var(--whatsapp-color) !important;
  }
  .btn-social:hover.telegram {
    color: var(--telegram-color) !important;
    border:2px solid var(--telegram-color) !important;
  }
  .btn-social:hover.twitter {
    color: var(--twitter-color) !important;
    border:2px solid var(--twitter-color) !important;
  }
  .btn-social:hover.pinterest {
    color: var(--pinterest-color) !important;
     border:2px solid var(--pinterest-color) !important;
  }
  .btn-social:hover.skype {
    color: var(--skype-color) !important;
     border:2px solid var(--skype-color) !important;
  }
  .btn-social:hover.linkedin {
    color: var(--linkedin-color) !important;
     border:2px solid var(--linkedin-color) !important;
  }
  .btn-social:hover.instagram {
    color:var(--instagram-color) !important;
     border:2px solid var(--instagram-color) !important;
  }
  .btn-social:hover.google-plus {
    color:var(--google-plus-color) !important;
     border:2px solid var(--google-plus-color) !important;
  }
  .btn-social:hover.youtube {
    color: var(--youtube-color) !important;
     border:2px solid var(--youtube-color) !important;
  }
  .btn-social:hover.tiktok {
    color: black !important;
     border:2px solid black !important;
  }
  .btn-social:hover.email-btn {
    color: var(--youtube-color) !important;
     border:2px solid var(--youtube-color) !important;
  }
  .clicked-btn{
    position: relative;
  }
  .clicked-btn:active{
    top: 5px;
  }
/*End btn */
/*start list unstyled*/
.profile__agent .profile__agent__group .profile__agent__header-avatar .list-unstyled {
    margin-left: 15px;
}
.profile__agent .profile__agent__group .profile__agent__header-avatar .list-unstyled li {
    margin-bottom: 5px;
}
.profile__agent .profile__agent__group .profile__agent__header-avatar .list-unstyled li a {
    font-size: 14px;
    font-weight: 500;
    color: #002247;
}
.profile__agent .profile__agent__group .profile__agent__header-avatar .list-unstyled li a i.fa {
    font-size: 15px;
}
.single__detail-features .list-unstyled.icon-checkbox {
    -moz-columns: 3;
    columns: 3;
    -moz-column-gap: 20px;
    column-gap: 20px;
    margin-bottom: 0;
}

.single__detail-features .list-unstyled.icon-checkbox li {
    line-height: normal;
    -moz-column-break-inside: avoid;
    break-inside: avoid;
    display: block;
    padding: 8px 0;
    text-transform: capitalize;
    position: relative;
    font-size: 14px;
}
.single__detail-features .list-unstyled.icon-checkbox li::before {
    display: inline-block;
    width: 19px;
    height: 19px;
    margin-right: 10px;
    top: -1px;
    position: relative;
    content: "\f00c";
    font-family: "FontAwesome";
    font-size: 12px;
    color: var(--primary-color);
    text-align: center;
    line-height: 15px;
    border: 2px solid transparent;
    transition: border-color 0s;
    border-radius: 2px;
}
.single__detail-features .list-unstyled.icon-checkbox li::before {
    background-color: #c1d0ff;
}
/*End list unstyled*/
/*badge primary*/
.badge-primary {
    color: #fff;
    background-color: var(--primary-color);
}
a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #2742ab;
}
a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(52, 84, 209, 0.5);
}
/*badge primary*/
/*widget*/
.widget__archive, .widget__category {
    margin-bottom: 30px;
}
.widget__archive .widget__title, .widget__category .widget__title {
    padding: 2rem 1rem;
    border-bottom: 1px solid #e9ecef;
}
.widget__archive .widget__title h5, .widget__category .widget__title h5 {
    margin-bottom: 0;
    font-size: 18px;
}
.widget__archive .widget__title h6, .widget__category .widget__title h6,
.widget__archive .widget__title h5, .widget__category .widget__title h5{
    text-transform: capitalize;
    text-align: start;
}
.widget {border: 1px solid #e9ecef;}
.widget ul {margin: 0;padding: 0;}
.widget ul li:last-child {border-bottom: 0;}
.widget ul li  {
  display: flex;
  align-items: center;
  justify-content: space-between;
    padding: 15px 20px;
    text-decoration: none;
    color: #222;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 0.3px;
    transition: all 0.3s ease;
    line-height: 26px;
    border-bottom: solid 1px #e6e6e6;
    text-align: start;
}
.widget ul li:hover {padding: 15px 20px 15px 30px;}
.categories-list-item .badge {
    display: block;
    float: right;
    border-radius: 4px;
    height: 26px;
    width: 26px;
    text-align: center;
    padding: 6px 0;
    font-size: 12px;
    line-height: 14px;
    font-weight: bold;
}
.widget__category {border: solid 1px #e6e6e6;}
.widget__category ul {margin: 0;padding: 0;}
.widget__category ul li a {background-color: #f8f8f8;}
.widget__category ul li a:hover {background-color: var(--primary-color);color: #fff;}
.widget__category ul li a span {font-size: 14px;}
/*widget*/
/*colors*/
.primary-color {
  color: var(--primary-color) !important;
}
/*colors*/
/*page*/
.page {min-height: 100vh;}
/*page*/
/*overlay*/
.overlay,.overlay_2 {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  /* z-index: 5; */
}
.overlay {
  background-color: rgba(0, 0, 0, 0.6);
}
.overlay_2 {
  background-color: rgba(0, 0, 0, 0.3);
}
.overlay-light {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(252, 251, 251, 0.2);
  z-index: 5;
}
.overlay-c::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    right:0;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.4);
}
/*End overlay*/
/*Start transition*/
.transition{
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
}
/*End transition*/
/*start z-index*/
.z-5{z-index:50;}
.z-10{z-index:100;}
.z-100{z-index: 1000;}
.z-200{z-index: 2000;}
/*End z-index*/
/*Start form style*/
.form-password{
  position:relative;
  user-select: none !important;
}
/*fade animation*/
.fade{
  animation: fade 0.5s linear forwards;
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
/*fade animation*/

.not-available{
  height: 600px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  }
  .not-available img{
  width: 600px;
  }


/*position*/
.top-10{top:10px;}
.right-10{right:10px;}
/*position*/
/*padding*/
.p-3px{padding: 3px;}
.p-5px{padding: 5px;}
/*padding*/
/*start hover*/
.hover-scale-img {
   overflow: hidden;
}
.hover-scale-img:hover img{
    transform: scale(1.2);
}
/*End hover*/
/*flex*/
.fit-flex{
  display: flex;align-items:center;justify-content: center;
}
/*end flex*/
/*media query*/
@media (max-width:575px) {
  .comment-wrapper p{font-size: 14px;}
  .img-wrapper{width: 50px;height: 50px;}
  .btn-social{width: 25px;height: 25px;}
  .not-available img{
    width:320px;
    }
    .single__detail-features .list-unstyled.icon-checkbox {
      columns: 1;
      -webkit-columns: 1;
      -moz-columns: 1;
    }
    .fs-55{font-size: 40px;}
}

@media screen and (min-width: 576px) and (max-width: 768px) {
  .single__detail-features .list-unstyled.icon-checkbox {
  -moz-columns: 2;
  columns: 2;
}
}