.section-title{
   margin: 20px auto;
   max-width: 460px;
}
.section-title h2{
    font-size: 27px;
    color: #002247;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 15px;
}
.section-title h2::before{
    flex-grow: 1;
    height: 1px;
    content: "\a0";
    background-color: #ddd;
    position: relative;
    top: 0.5em;
    margin: 5px 15px;
}
.section-title h2::after{
    flex-grow: 1;
    height: 1px;
    content: "\a0";
    background-color: #ddd;
    position: relative;
    top: 0.5em;
    margin: 5px 15px;
}
.section-title p{
    color: #495057;
    margin-bottom: 0;
    font-size: 16px;
}