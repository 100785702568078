/*Start search */

.search-box .intro-title {
  overflow: hidden;
}

@keyframes showup {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.search__property .nav .nav-item{
  color:white;
  padding: 10px;
  margin:0 5px;
  width: 130px;
  text-align: center;
  border:2px solid white;
  background-color:#80808066;
  border-radius:8px;
  position: relative;
  cursor: pointer;
}
.search__property .nav .nav-item.active{
  background-color:var(--primary-color);
  border:2px solid var(--primary-color);
}
.search__property .nav .nav-item.active::after{
  content: '';  
  position: absolute;  
  left: 50%;
  transform: translateX(-50%); 
  top: 100%;   
  border-width: 10px;  
  border-style: solid;  
  border-color: var(--primary-color) transparent transparent transparent; /* Arrow color */  
}
.search__property .tab-content{
  background: white;
    padding:25px;
    border-radius: 15px;
}


/*End search */
/*Start wrap content*/
.jumbotron {
  position: relative;
  background-color: #d1e6f9;
  height: 100vh;
  min-height: 25rem;
  width: 100%;
  padding: 0;
  margin: 0;
  z-index: 2;
}
.wrap__intro {
  position: relative;
  text-align: start;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
}
.wrap__intro h1,
.wrap__intro h4 {
  color: #fff;
  text-transform: capitalize;
  font-weight: 700;
}
.wrap__intro .second-title {
  font-size:64px;
}
.wrap__intro h4 {
  line-height: 1.7;
  background-color: var(--primary-color);
  display: inline-block;
  padding: 0.2rem 0.5rem;
  font-size: 16px;
  font-weight: 500;
}
.wrap__intro p {
  font-size: 24px;
    color: #D3D3D3;
    margin: 40px;
}
/*fade animation on tab content*/
.tab-content > .tab-pane {
  display: none;
  opacity: 0;
  animation: fade 0.5s linear forwards;
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
.tab-content > .active {
  display: block;
}
/*fade animation on tab content*/
/*video background*/
.fullscreen-bg,
.fullscreen-bg__video {
  width: 100%;
  height: 100%;
}
.fullscreen-bg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}

/*End wrap content*/



/*start nav*/
.search-box nav li{
  background-color:#80808066;
  width: 160px;
}
.search-box nav li:hover{
  background-color:var(--primary-color);
}
/*end nav*/
/*media query*/
@media screen and (min-width: 320px) and (max-width: 767px) {
  .search-box {
    margin-top: 10px;
    font-size: 14px;
    height: auto;
  }
  .search-btn {
    margin-top: 10px;
  }
  .search__container .select_option ul.list {
    width: 100%;
  }
  .jumbotron {
    height: auto;
    z-index: 4;
  }
  .wrap__intro {
    padding-top: 1rem;
    padding-bottom: 2rem;
  }
  .wrap__intro-heading {
    margin-right: 0;
    max-width: 100%;
  }
  .wrap__intro .shahid-title {
    font-size:35px;
  }
  .wrap__intro .second-title {
    font-size: 35px;
  }
  .wrap__intro p,
  .wrap__intro h4 {
    font-size: 14px;
  }
  .wrap__intro p{margin-bottom: 5px;}
  .search__container input {
    border-left: 0;
    border-top: 1px solid #e9ecef;
    width: 100% !important;
  }

  .search__container .input-group-append {
    width: 100%;
    margin-left: 0;
  }
  .search-box nav li{
    width: 100%;
  }
}


@media screen and (min-width: 576px) and (max-width: 768px) {
  .wrap__intro h1 {
    font-size: 30px;
  }
  .jumbotron {
    height: auto;
  }
  .search__container .select_option span {
    font-size: 14px;
  }
  .search__container .select_option {
    padding: 10px;
  }
  .search__container input {
    border-left: 0;
  }
  .search__container .input-group-append .btn {
    padding: 10px;
  }
  .search__property .search__container .select_option {
    border-top: 0.5px solid #ddd;
  }
}
@media screen and (min-width: 768px) and (max-width: 992px){
  .wrap__intro .second-title {
    font-size: 30px;
  }
  .wrap__intro .shahid-title{
    font-size: 35px;
  }
}
@media (max-width: 992px) {
  .search-btn {
    margin-top: 10px;
  }
  .wrap__intro p{margin-bottom: 5px;}
}
@media (min-width:992px) {
  .search-box {
    height: calc(100vh - 77px);
  }
}
/*media query*/
