.selected-images-wrapper{
    border:2px solid var(--primary-color),
}
.selected-images-wrapper img{
    width: 150px;
    height: 150px;
}
.remove-btn{
    top:5px;
    right:5px;
    border-radius:50%;
}