.auth-page{
    overflow: hidden;
}
.auth-page section{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 15px;
    height: 100vh;
    width: 100vw;
}
.auth-page .mask-auth{
    background-image: url(../../images/mask-auth.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.auth-card{
    width: 100%;
    font-size: 16px;
    max-width: 500px;
    width: 500px;
    margin: 0 auto;
    border-radius: 10px;
    padding: 0px 30px 30px 30px;
    text-align: start;
}
.auth-card .card-title{text-align:center;}

.hide-scroll{
    height: 100vh;
    scrollbar-width: none;
    overflow-y: auto;
}


@media (max-width:575px) {
    .auth-card{
        width: 350px;
    }
    .auth-page section{
        height: auto;
    }
}