.landing{
    background-image: url('../../../images/home-bg.jpg');
    /* background-position: center; */
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 100vh;
    min-height:100vh;
    width: 100vw;
}
.landing-overlay{
    background:linear-gradient(to left, #000000c2, #6d6d6dd1);
}
@media (max-width:992px) {
    .landing{
        height: auto;
    }
}