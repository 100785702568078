.user-setting-page{
    display: flex;
    justify-content:flex-end;
}
.user-setting-page .form-group{
    margin: 10px 0 !important;
}
.user-setting-page .spinner{
    position: absolute;
    top: 0;
    left: 0;
    background: #ffffff59;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.user-sidebar-wrap{
    flex-basis:300px;
    width: 300px;
    position: fixed;
    top: 0;
    left: 0;
}
.user-dashboard{
    flex-basis:calc(100% - 300px);
    flex-shrink: 0;
}
.user-setting-page .dashboard-main-content{
    background-color: var(--section-bg);
}
.user-setting-page .dashboard-main-content .form-box{
    background: white;
    text-align: start;
    border-radius: 10px;
    box-shadow: 0 0 5px 0px #ccc;
    padding:20px;
}
.label-text{
    text-transform: capitalize;
    font-size: 18px;
}
.user-img{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-right: 15px;
    overflow: hidden;
}
.user-img img{
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
}
.user-setting-page .dashboard-main-content .form-box-1,
.user-setting-page .dashboard-main-content .form-box-2{
    position: relative;
    top: -50px;
    
}
.user-setting-page .dashboard-main-content .form-box-1 .user-pro-img{
    width: 150px;
    margin-right: 10px;
}
.user-setting-page .dashboard-main-content .form-box-1 .user-pro-img img{
    max-width: 100%;
}
.user-setting-page .dashboard-main-content .form-box-1 p{padding: 0 !important;}
.user-setting-page .dashboard-main-content .form-box-1 .file-upload-wrap label{
    padding: 8px 12px;
    background: #eee;
    border-radius: 3px;
    margin: 5px 0;
}
.user-setting-page .dashboard-main-content .form-box-1 .btn-box{
    margin: 10px 0;
}
.user-setting-page .dashboard-main-content .form-box-1 .btn-box .btn{
    width: 100%;
    padding:10px;
}
.user-setting-page .dashboard-main-content .form-group{
   position: relative;
}
.user-setting-page .dashboard-main-content .form-group .custom-input{
  font-size: 12 !important;
}
.user-setting-page .dashboard-main-content .form-group input{
   padding-left:30px;
}
.user-setting-page .dashboard-main-content .form-group svg{
   position: absolute;
   left:10px;
   top: 50%;
   transform: translateY(-50%);
   color: #8080808f;
}
.user-setting-page .dashboard-main-content .input-box{
    margin-bottom: 20px;
}


.user-setting-page .dashboard-main-content .row-content h3{
    font-size: 18px;
}
.user-setting-page .dashboard-main-content .row-footer{
    padding: 10px;
}

.show-password{
    position: absolute;
    right:30px;
    top: 50%;
}
.show-password-ar{
    position: absolute;
    left:30px;
    top: 50%;
}
.show-password svg{
    position:unset !important;
}
@media (max-width:992px) {
    .user-setting-page .user-dashboard{
        flex-basis:100%;
    }
}