.dashboard-bread{
    background: var(--primary-color);
    padding: 20px;
    height: 180px;
    margin-bottom: 20px;
}
.dashboard-bread .breadcrumb-list .list-items {
    padding: 10px 20px !important;
    background: #0000005e;
    border-radius: 5px;
    min-width: 215px;
    max-width: 215px;
    margin: 0 auto !important;
}