.want-to-sell{
    background-image: url(../../../images/bg.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.want-to-sell-form{
    background: gainsboro;
    padding: 20px;
    margin: 0 auto;
    border-radius: 10px;
    max-width: 550px;
}
.want-to-sell-form .section-title {
   margin: 0 !important;
}