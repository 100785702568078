
 .slide-show .img-wrapper{
     max-height: 500px;
     height: 500px;
 }
 .slide-show .img-content-item-right{
     max-height: 500px;
     height: 500px;
     position: relative;
 }
 .slide-show .img-content-item-right .show-all-imgs{
     position: absolute;
     right: 40px;
     bottom: 19px;
     background-color: var(--primary-color);
     color: white;
     padding: 5px;
     border-radius: 20px;
     font-weight: 600;
     font-size: 14px;
     width: 100px;
     cursor: pointer;
 }
 .slide-show .img-content-item-left{
     max-height:250px;
     height: 250px;
 }
 .slide-show img{
     max-width: 100%;
     max-height: 100%;
     user-select: none;
 }
 .slide-show .popup-swiper{
     display: none;
     position: fixed;
     top: 0;
     left: 0;
     width: 100vw;
     height: 100vh;
     z-index: 1000;
     background:#000000de;
 }
 .slide-show .popup-swiper.active{
     display: block !important;
 }
 .slide-show .popup-swiper .popup-swiper-wrapper{
     height: 100%;
     width: 100%;
     display: flex;
     align-items: center;
     justify-content: center;
     padding: 50px;
     position: relative;
 }
 .slide-show .popup-swiper .popup-swiper-wrapper .swiper-close-btn{
     position: absolute;
     top: 20px;
     left: 20px;
     background-color:var(--primary-color);
     color:white;
     font-size: 20px;
     width: 45px;
     height: 45px;
     border-radius:50%;
     line-height: 2.1;
     cursor:pointer;
     
 }
 .slide-show .popup-swiper .popup-swiper-wrapper .swiper-close-btn:hover,
 .slide-show .img-content-item-right .show-all-imgs:hover{
     background-color: white;
     color: var(--primary-color);
 }
 /* .slide-show .swiper-wrapper{height: 90% !important;} */
 .slide-show .mySwiper2{
     height:750px;
     max-height:750px;
     display: flex;
     align-items: center;
     justify-content: center;
 }
 .slide-show .mySwiper2 .swiper-slide{
     background:#00000000;
 }
 .sidlo{
     position: relative;
     height: 100%;
     max-height: 100%;
     display: flex;
     align-items: center;
     justify-content: center;
 }
 .swiper-wrapper{
     height: auto !important;
 }
 @media (max-width:575px) {
     .slide-show{
         min-height: 300px;
     }
     .slide-show .mySwiper2{
         height:400px;
         max-height:800px;
     }
     .slide-show .img-wrapper{
         max-height: 320px;
         height: 320px;
     }
     .slide-show .img-content-item-right{
         max-height: 300px;
         height: 300px;
     }
     .slide-show .img-content-item-left{
         max-height:150px;
         height: 150px;
     }
     .slide-show .popup-swiper .popup-swiper-wrapper{padding: 25px;}
 }
 