/*products filter*/
.products__filter{
    padding: 25px;
    text-align: start;
}
/*filter products*/
.products__filter {
    border: 1px solid #e9ecef;
}
.products__filter .products__filter__group {
    border-bottom: 1px solid #e9ecef;
}
.products__filter .products__filter__group .products__filter__header {
    padding: 2rem 1rem;
    margin-bottom: 0;
    display: block;
}
.products__filter .products__filter__group .products__filter__header h5 {
    text-transform: capitalize;
    color: #212529;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0;
    line-height: 24px;
}
.products__filter .products__filter__group:last-child {
    border-bottom: 0;
}
.products__filter .products__filter__group .products__filter__body {
    /* padding: 2rem 1.5rem 1rem; */
    border-top: 1px solid #e9ecef;
    transition: all linear 0.25s;
}
.products__filter .products__filter__group .products__filter__body .form-group {
    display: inline-block;
    width: 100%;
    margin: 15px 0;
}
.products__filter .products__filter__group .products__filter__body .form-group label {
    font-weight: 500;
    font-size: 12px;
}
.products__filter .products__filter__group .products__filter__body .form-group .nice-select {
    border-radius: 0;
}
@media screen and (min-width: 320px) and (max-width: 575px) {
    .products__filter .products__filter__group .products__filter__body .form-group .nice-select {
    padding-left: 6px;
    padding-right: 6px;
}
}.products__filter .products__filter__group .products__filter__body .form-group .irs--big .irs-handle {
    border: 1px solid var(--primary-color);
    background-color: var(--primary-color);
    background: var(--primary-color);
    box-shadow: none;
    width: 20px;
    height: 25px;
    border-radius: 0;
}
.products__filter .products__filter__group .products__filter__body .form-group .irs-from {
    background-color: var(--primary-color);
    background: var(--primary-color);
}
.products__filter .products__filter__group .products__filter__body .form-group .irs-from::before {
    border-top-color: var(--primary-color);
}
.products__filter .products__filter__group .products__filter__body .form-group .irs-to {
    background-color: var(--primary-color);
    background: var(--primary-color);
}
.products__filter .products__filter__group .products__filter__body .form-group .irs-to::before {
    border-top-color: var(--primary-color);
}
.products__filter .products__filter__group .products__filter__body .form-group .irs--flat .irs-handle>i:first-child {
    background-color: var(--primary-color);
    margin-left: 0px;
    width: 5px;
}
.products__filter .products__filter__group .products__filter__body .form-group .irs-bar {
    background-color: var(--primary-color);
    background: var(--primary-color);
}
.products__filter .products__filter__group .products__filter__body .form-group a {
    font-size: 15px;
    font-weight: 500;
}
.products__filter .products__filter__group .products__filter__body .form-group a:hover {
    text-decoration: none;
}
.products__filter .products__filter__group .products__filter__body .form-group .advancedfilter {
    position: relative;
    margin-top: 10px;
    margin-left: 1px;
}
.products__filter .products__filter__group .products__filter__body .form-group .advancedfilter .checkbox {
    margin-bottom: 10px;
}
.products__filter .products__filter__group .products__filter__body .form-group .advancedfilter .checkbox label:before, .products__filter .products__filter__group .products__filter__body .form-group .advancedfilter .checkbox label::after {
    margin-left: -17px;
}
.products__filter .products__filter__group .products__filter__body .form-group .input-group-prepend .input-group-text {
    padding: 9px 1rem;
    border: 1px solid #e9ecef;
}
.products__filter .products__filter__group .products__filter__footer {
    padding: 1rem;
    border-top: 1px solid #e9ecef;
    transition: all linear 0.25s;
}
/*filter products*/