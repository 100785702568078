.header {
  padding: 8px;
}
.header .nots-box {
  left: 50%;
  transform: translateX(-50%);
  width: 300px;
  height: 0px;
  min-height: 0px;
  max-height: 0px;
  font-size: 12px;
  background: white;
  color: gray;
}
.nots-box::-webkit-scrollbar {
  width: 0px;
}
.header .nots-box.active {
  height: 400px;
  min-height: 400px;
  max-height: 400px;
}
.profile-btn-wrapper .img-box {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid var(--primary-color);
}
.logo-box .company-logo {
  width: 140px;
  max-width: 100%;
}
.btns-parent,
.logo-box .company-logo {
  margin: 0 50px;
}

.count-badge {
  width: 20px;
  height: 20px;
  top: -14px;
  right: -14px;
}

@media (max-width: 567px) {
  .logo-box .company-logo,
  .btns-parent {
    margin: 0 5px;
  }
}
@media (max-width: 767px) {
  .logo-box .company-logo {
    width: 85px;
  }
}
@media (min-width:767px) and (max-width:1200px) {
  .logo-box .company-logo {
    width: 105px;
  }
}
