.navbar .nav-item{cursor: pointer;}
.ar-img,.en-img{
    width: 40px;
    height: 25px;
}
.dropdown-menu.lang-dropdown{
    background-color: transparent;
}
.dropdown-menu.lang-dropdown .dropdown-item{border: none !important;}
.dropdown-menu.lang-dropdown .dropdown-item:hover{background-color:transparent !important;}

.star-badge{
    left: -15px;
    top: 20px;
    background: white;
    padding: 0px 6px;
    border-radius: 5px;
    color:var(--primary-color);
    font-size: 12px;
}